<template>
    <el-drawer
        :title="showType === 'list' ? '处理日志' : showType === 'callLog' ? '通知记录' : isFeedBack ? '风险事件反馈详情' : '风险事件处理详情'"
        :visible="showLog"
        :direction="`rtl`"
        :size="780"
        :before-close="visibleClose"
    >
        <div class="content" v-loading="loading" element-loading-text="加载中">
            <!-- 列表 -->
            <div class="listBox" v-if="showType === 'list'">
                <p class="lineItem" v-for="(item, index) in list" :key="index">
                    <span>{{ item.creatTime }}</span>
                    <span>{{ item.content }}</span>
                    <!-- callStatus 通话状态:0:未接听 1:已接听  -->
                    <el-tag v-if="item.callStatus || item.callStatus === 0" :type="item.callStatus === 0 ? 'danger' : ''">
                        {{ item.callStatus === 0 ? '未接听' : '已接听' }}
                    </el-tag>
                    <el-button v-if="item.callId" type="text" @click="handleType('callLog', item, false)">通知记录</el-button>
                    <el-button v-if="item.dealId" type="text" @click="handleType('details', item, item.content.indexOf('反馈') !== -1)">
                        {{ item.content.indexOf('反馈') !== -1 ? '反馈详情' : '处理详情' }}
                    </el-button>
                </p>
            </div>
            <!-- 通话记录 -->
            <CallLog v-if="showType === 'callLog' && currentItem.callId" :currentItem="currentItem" />
            <!-- 处理反馈详情 -->
            <HandleDetails v-if="showType === 'details'" :currentItem="currentItem" :riskId="riskId" :isFeedBack="isFeedBack" />
        </div>

        <div style="text-align: center; margin-top: 16px">
            <el-button @click="visibleClose">关闭</el-button>
        </div>
    </el-drawer>
</template>
<script>
import { selectRiskLog } from '@/api/homeSave'
import CallLog from './callLog.vue'
import HandleDetails from './handleDetails.vue'
export default {
    // 风险日志
    name: 'riskLog',
    components: {
        CallLog,
        HandleDetails
    },
    props: {
        showLog: {
            type: Boolean,
            default: false
        },
        riskId: null
    },
    data() {
        return {
            loading: false,
            list: [],
            showType: 'list',
            isFeedBack: false,
            currentItem: null // 当前操作的一条数据
        }
    },
    mounted() {
        console.log(this.riskId, 'riskId')
        this.getList()
    },
    methods: {
        visibleClose() {
            if (this.showType === 'list') {
                this.$emit('update:showLog', false)
            } else {
                this.showType = 'list'
            }
        },
        getList() {
            this.loading = true
            selectRiskLog({ riskId: this.riskId })
                .then(res => {
                    this.list = res.data
                    this.list.forEach(item => {
                        const userTypeStr = item.userType ? `(${['', '网格员', '网格长', '监督员', '值班员', '协助人员', '紧急联系人', '本人', '亲友'][item.userType]})` : ''
                        item.content = item.content.replace('()', userTypeStr)
                    })
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        },
        // 通知详情
        handleType(type, item, isFeedBack) {
            this.currentItem = item
            this.showType = type
            this.isFeedBack = isFeedBack
        }
    }
}
</script>

<style lang="scss" scoped>
/deep/ .el-drawer__header {
    padding: 20px 0 20px 0;
    font-size: 25px;
    border-bottom: 0px solid #ccc;
    margin-bottom: 0;
    &::before {
        content: '';
        width: 9px;
        height: 30px;
        background: #3fcf89;
        float: left;
        margin-right: 20px;
    }
}
.content {
    height: 90%;
    width: 100%;
    overflow-y: auto;
}
.lineItem {
    padding: 0 20px 10px 20px;
    span {
        margin-right: 10px;
    }
}
</style>
